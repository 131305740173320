<template>
  <div class="page-main-bg">
    <v-container class="main-container team-container position-relative">
      <!--tool-bar-->
      <tool-bar v-bind="toolbarProps">
        <slot />
      </tool-bar>

      <!--filters-->
      <contracts-filters
        ref="contractFilter"
        :available-filters="availableFilters"
        :sort-options="sortOptions"
        :gridView="gridView"
        @handleFilters="handleFilters"
        @gridViewChanged="changeGridView"
      />

      <!--Loader-->
      <loader v-if="isPageLoading" v-bind="loaderProps" class="mt-2" />

      <!--no contract-->
      <no-data v-else-if="!contracts.length" v-bind="noDataProps" />

      <!---------table view----------->
      <!----------------------------->
      <!---------Contract-card------->
      <DataTableContracts
        v-else-if="!gridView"
        v-bind="dataTableContractProps"
        @updatedContractData="setUpdatedContractdata"
        @confirmRenew="confirmRenew"
        @confirmExpiredRenew="confirmExpiredRenew"
        @showDetails="setDetails"
        @showVersions="showSideVersions"
        @onTerminate="terminateContract"
        @sharePdf="sharePdf"
        @openPDF="setPDFDetails"
        @resendToClient="resendToClient"
        @refreshContracts="refreshContracts"
      />

      <!---------grid view----------->
      <!----------------------------->
      <!---------Contract-cards------->
      <contract-card
        v-else
        v-for="contract in contracts"
        :key="contract._id"
        v-bind="{ item: contract, contractIntervals }"
        @updatedContractData="setUpdatedContractdata"
        @resendToClient="resendToClient"
        @confirmRenew="confirmRenew"
        @confirmExpiredRenew="confirmExpiredRenew"
        @showDetails="setDetails"
        @showVersions="showSideVersions"
        @onTerminate="terminateContract"
        @sharePdf="sharePdf"
        @openPDF="setPDFDetails"
        @refreshContracts="refreshContracts"
        class="set__hover"
      />

      <!--sideDetailes-->
      <v-layout v-if="contractDetails && sideDetailes">
        <sideContractsDetailes
          :item="contractDetails"
          :contractIntervals="contractIntervals"
          @closeDetails="setDetails"
          @onTerminate="terminateContract"
          @openPDF="exportPdfContract"
          @updatedContractData="setUpdatedContractdata"
          @confirmRenew="confirmRenew"
          @confirmExpiredRenew="confirmExpiredRenew"
          @resendToClient="resendToClient"
          @openNoteModal="displayNoteModal(true)"
          @previewNote="previewNote"
          @deleteNote="deleteContractNote"
          @hide-task="setSideDetailes(false)"
          @showSideDetails="setSideDetailes(true)"
          @setDetailsItem="setDetails"
        />
      </v-layout>

      <!--versionDetails-->

      <!-------FastActions---------->
      <div class="d-flex align-center justify-end ml-md-8 fast-actions">
        <v-btn
          v-if="!isCustomer"
          color="primary"
          large
          class="font-weight-bold fast-actions-btn pa-5 font-12"
          @click="openAddDialog"
        >
          <img
            src="@/assets/img/svgs/001-writing.svg"
            class="mx-2"
            loading="lazy"
          />
          <span class="mb-1">انشاء عقد</span>
        </v-btn>

        <!----------------------------->
        <!-------Fast actions---------->
        <!----------------------------->
        <fastActions />
      </div>

      <!--Pagination-->
      <div class="text-center mt-6 mb-5" v-if="pagination.pagesCount > 1">
        <Pagination :pagination="pagination" @onChange="loadData()" />
      </div>

      <!--AddContractModal-->
      <AddContractModal
        :contractModalState="showContractModal"
        :contractIntervals="contractIntervals"
        :updatedContract="updatedContract"
        @closeContractModal="closeContractModal"
        @refreshContracts="refreshContracts"
        @resetRelaestate="resetRelaestate"
        v-if="showContractModal"
      />

      <!-- -->
      <cancelContractModal
        :contractModalState="showCancelContractModal"
        :contract="contractDetails"
        :cancellationReasons="cancellationReasons"
        @closeCancelContractModal="showCancelContractModal = $event"
        @onTerminate="manageTerminateContract"
        @getCancellationReasons="getCancellationReasons"
        v-if="showCancelContractModal"
      />

      <!--Add note modal -->
      <AddNoteModal
        v-if="addNoteDialog"
        v-bind="{ dialogState: addNoteDialog, isAddNoteLoading }"
        :note="editNote"
        :modelTitles="noteModelTitle"
        @closeNoteModal="displayNoteModal(false)"
        @addNote="addContractNote"
      />

      <!--ContractPDF-->
      <contractPDF
        v-if="pdfStatus"
        v-bind="contractPdfProps"
        @closePdf="togglePdfStatus"
        @refreshContracts="refreshContracts"
      />
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { contractsService, bondsService } from '@/services'
import setView from '@/mixins/setView'
import ContractCard from '@/components/contracts/ContractCard.vue'
import ContractsFilters from '@/components/contracts/ContractsFilters'
import sideContractsDetailes from '@/components/contracts/sideContractsDetailes'
import ToolBar from '@/components/listing/ToolBar'
import Pagination from '@/components/helper/pagination.vue'
import contractPDF from '@/components/contracts/ContractPdf.vue'
import DataTableContracts from '@/components/contracts/DataTableContracts.vue'
import fastActions from '@/components/helper/fastActions'
import noData from '@/components/helper/noData.vue'
import loader from '@/components/helper/loader.vue'
import generateShareCopy from '@/mixins/generateShareCopy'
import confirmationMixin from '@/mixins/confirmationMixin.js'

export default {
  name: 'contracts',
  components: {
    fastActions,
    AddContractModal: () => import('@/components/add-contract/contractModal'),
    cancelContractModal: () =>
      import('@/components/add-contract/cancelContractModal'),
    sideContractsDetailes,
    ToolBar,
    ContractsFilters,
    ContractCard,
    Pagination,
    contractPDF,
    DataTableContracts,
    noData,
    loader,
    AddNoteModal: () => import('@/components/modals/AddNoteModal.vue')
  },
  mixins: [setView, generateShareCopy, confirmationMixin],
  data() {
    return {
      editNote: '',
      noteModelTitle: {},
      cancellationReasons: [],
      clientRoles: {
        renter: 'tenet',
        owner: 'owner'
      },
      exceedTitle:
        'لقد استنفذت العدد المحدد بناءا على الباقة الحالية. يمكنك ترقية باقتك لتتمكن من إجراء إضافات جديدة',
      exceedSubTitle:
        'هذه الخاصية غير متاحة في هذه الباقة. يمكنك ترقية الباقة الحالية لباقة أعلى لتتمكن من إستخدام هذه الخاصية',
      noDataConfig: {
        noDataText: 'لا يوجد عقود مضافه حتي الأن',
        noDataSubTitle:
          'تظهر جميع العقود المضافة في هذه القائمة مع إمكانية عرض تفاصيل كل عقد على حده',
        noDataIcon: require('@/assets/img/dashboard-icons/contract-icon.png')
      },
      noDataConfigForTerminated: {
        noDataText: 'لا يوجد عقود محذوفة',
        noDataSubTitle: 'يمكن حذف العقد نهائيا,أو الاحتفاظ بنسخه من هذا العقد'
      },
      headers: [
        {
          text: 'رقم العقد',
          align: 'right',
          sortable: false,
          value: 'number'
        },
        {
          text: 'عنوان العقد',
          align: 'right',
          sortable: false,
          value: 'title'
        },
        { text: 'توع العقد', value: 'type', align: 'center', sortable: false },
        {
          text: 'تاريخ بدايه التعاقد',
          value: 'start',
          align: 'center',
          sortable: false
        },
        {
          text: 'عقد بواسطه',
          value: 'moderator',
          align: 'center',
          sortable: false
        },
        {
          text: 'حاله العقد',
          value: 'status',
          align: 'center',
          sortable: false
        },
        { text: '', value: 'actions', align: 'center', sortable: false }
      ],
      pdfStatus: false,
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 5,
        count: 0
      },
      noContract: require('@/assets/img/svgs/contract/noContracts.svg'),
      availableFilters: [
        {
          nameAr: 'الكل',
          nameEn: 'count',
          code: 'count',
          count: 0
        },
        {
          nameAr: 'إداره أملاك',
          nameEn: 'propertyManagement',
          code: 'propertyManagementCount',
          count: 0
        },
        {
          nameAr: 'صيانة',
          nameEn: 'maintenance',
          code: 'maintenanceCount',
          count: 0
        },
        {
          nameAr: 'ايجار',
          nameEn: 'rent',
          code: 'rentCount',
          count: 0
        }
      ],
      breadcrumbs: [
        {
          text: ' قائمة العقود',
          disabled: false,
          exact: true,
          link: true,
          to: { path: '/dashboard/contracts' }
        }
      ],
      sortOptions: [
        { nameAr: 'الأحدث', nameEn: 'Newest' },
        { nameAr: 'الأقدم', nameEn: 'Oldest' }
      ],
      contractsFilters: {
        sort: 'Newest'
      },
      sideDetailes: false,
      isPageLoading: false,
      gridView: true,
      contracts: [],
      showContractModal: false,
      showCancelContractModal: false,
      isSideVersionsVisible: false,
      currentSelectedContract: null,
      contractDetails: null,
      contractIntervals: [],
      updatedContract: {},
      addNoteDialog: false,
      isAddNoteLoading: false
    }
  },
  watch: {
    customerDefaultRole() {
      this.loadData()
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user', 'customerDefaultRole']),
    ...mapGetters('accountState', ['isCustomer', 'companyImages']),
    localStorageContract() {
      return JSON.parse(localStorage.getItem('sideDetailsItem'))
    },
    isRenterCustomer() {
      return this.isCustomer && this.customerDefaultRole == 'renter'
    },
    contractTableHeaders() {
      if (this.$hasPermission('properties', 'contracts', 'delete')) {
        return this.headers
      }
      const filterdHeaders = this.headers.filter(
        (header) => header.value != 'checked'
      )
      return filterdHeaders
    },
    hasPermission() {
      return this.$hasPermission('properties', 'contracts', 'add')
    },
    getContractFilters() {
      let dynamicFilter = {}
      if (this.isCustomer) {
        dynamicFilter = {
          ...this.contractsFilters,
          users: this.user._id,
          userType: this.clientRoles[this.customerDefaultRole]
        }
      } else {
        dynamicFilter.environment = this.currentEnv._id
      }
      this.contractsFilters = { ...this.contractsFilters, ...dynamicFilter }
      return this.contractsFilters
    },
    noDataProps() {
      return this.noDataConfig
    },
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    dataTableContractProps() {
      return {
        contracts: this.contracts,
        gridView: this.gridView,
        headers: this.contractTableHeaders
      }
    },
    toolbarProps() {
      return {
        breadcrumbs: this.breadcrumbs,
        title: 'العقود',
        subTilte: 'عقد',
        length: this.contracts?.length
      }
    },
    contractPdfProps() {
      return {
        showSigntures: true,
        item: this.contractDetails,
        contractIntervals: this.contractIntervals,
        ref: 'PDF'
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapActions('accountState', ['getCompanyImages', 'getContractsStatuses']),
    async getCancellationReasons() {
      const { data } = await contractsService.contractCancellationReasons()
      this.cancellationReasons = data.reasons
    },
    getPopupBody(contract, isIncluded) {
      if (
        contract.status === 'approved' ||
        contract.status === 'pendingRenewal'
      ) {
        return {
          title: 'هل أنت متأكد من إلغاء تجديد العقد',
          message: 'الرجاء اختيار نعم في حاله الرغبه في إلغاء عمليه التجديد',
          confirmTxt: 'تأكيد',
          cancelText: 'إلغاء'
        }
      }
      // else if (contract.status === 'pendingRenewal') {
      //   return {
      //     title:
      //       'تم إلغاء التجديد وإلغاء وحذف جميع البيانات المتعلقة بهذا العقد',
      //     options: {
      //       color: 'grey',
      //       width: 328,
      //       height: 278,
      //       zIndex: 200
      //     },
      //     message: 'وتم ايقاف كل المعاملات والفواتير المرتبطة بالعقد',
      //     confirmTxt: 'قائمه العقود',
      //     cancelButtonStatus: false
      //   }
      // }
      return {
        title: isIncluded
          ? 'هل أنت متأكد من إنهاء العقد'
          : 'هل أنت متأكد من إلغاء العقد',
        message: isIncluded
          ? 'سيتم إعتماد تاريخ الاخلاء كأخر يوم في التعاقد وحذف وإلغاء البيانات المستقبلية المتعلقة بهذا العقد'
          : 'سيتم حذف وإلغاء كل البيانات والعمليات المتعلقة بهذا العقد في حال إلغاء التعاقد',
        confirmTxt: 'تأكيد',
        img: {
          path: isIncluded
            ? require('@/assets/img/minus.png')
            : require('@/assets/img/svgs/closeImg.svg'),
          width: 56,
          height: 56
        },
        cancelText: 'إلغاء'
      }
    },
    openAddDialog() {
      if (!this.hasPermission) {
        this.addNotification({
          text: 'ليس لديك صلاحية لإضافة عقود',
          color: 'error'
        })
        return
      }
      this.showContractModal = true
    },
    handleOpenSideDetailsUsingNewTab() {
      if (this.contractDetails === null && !!this.localStorageContract) {
        this.setDetails({
          visible: true,
          contract: this.localStorageContract,
          contractUtilities: {}
        })
      }
    },
    restructureCompanyImages() {
      // restructure images to fit sharing
      const restructuredImages = {}
      for (const key in this.companyImages) {
        restructuredImages[key] = {
          key: this.companyImages[key].key,
          location: this.companyImages[key].path
        }
      }
      return restructuredImages
    },
    async resendToClient({ contractId }) {
      this.setSideDetailes(false)
      try {
        await contractsService.resendToCLient(contractId)
        this.addNotification({
          text: 'تم إعاده إرسال إشعار العقد بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ أثناء إعاده إرسال العقد',
          color: 'error'
        })
      }
    },
    getPopUpTitleText(isIncluded) {
      return isIncluded
        ? 'تم إنهاء العقد وإعتماد البيانات والعمليات لحين تاريخ الإخلاء'
        : 'تم إلغاء التجديد وإلغاء وحذف جميع البيانات المتعلقة بهذا العقد'
    },
    resetRelaestate() {
      delete this.updatedContract.realEstate
    },
    setUpdatedContractdata(contractData) {
      // const key = contractData.status === 'pendingRenewal' ? `تجديد` : 'نشر'
      this.setSideDetailes(false)
      // this.$root.$confirm
      //   .open({
      //     title: `تأكيد ال${key}`,
      //     message: `هل أنت متأكد من ${key} العقد`,
      //     img: {
      //       path: require('@/assets/img/svgs/exclamationMarkrounded.svg'),
      //       width: 56,
      //       height: 56
      //     },
      //     options: {
      //       color: 'primary'
      //     }
      //   })
      //   .then((confirm) => {
      //     if (confirm) {
      this.updatedContract = contractData
      this.showContractModal = true
      //   }
      // })
    },
    confirmExpiredRenew(item) {
      this.setSideDetailes(false)
      this.$root.$confirm
        .open({
          title: `تجديد العقد`,
          message: `إنشاء عقد جديد قابل للتجديد، يسمح بتحديث بيانات العقد الجديد واعتماده، ثم تأكيد عملية التجديد`,
          img: {
            path: require('@/assets/img/svgs/check-purple.svg'),
            width: 56,
            height: 56
          },
          options: {
            color: 'primary'
          },
          confirmTxt: 'تأكيد',
          cancelText: 'إلغاء'
        })
        .then(async (confirm) => {
          if (confirm) {
            try {
              delete item.methodTitle
              const { data } = await contractsService.renewExpiredContract({
                environment: this.currentEnv?._id,
                contract: item?._id
              })
              // @TODO: need to recap on approvals list
              data.needsApproval && this.showConfirmationPopupMixin()
              this.addNotification({
                text: 'تم إنشاء نسخه قيدالتجديد بنجاح',
                color: 'success'
              })
              this.refreshContracts()
            } catch {
              this.addNotification({
                text: 'حدث خطأ أثناء تجديد العقد',
                color: 'error'
              })
            }
          }
        })
    },
    confirmRenew(item) {
      this.setSideDetailes(false)
      this.$root.$confirm
        .open({
          title: `تأكيد العقد`,
          message: `هل أنت متأكد من تجديد العقد`,
          img: {
            path: require('@/assets/img/svgs/exclamationMarkrounded.svg'),
            width: 56,
            height: 56
          },
          options: {
            color: 'primary'
          }
        })
        .then(async (confirm) => {
          if (confirm) {
            try {
              delete item.methodTitle
              const { data } = await contractsService.renewContract({
                environment: this.currentEnv?._id,
                contract: item?._id
              })
              data.needsApproval && this.showConfirmationPopupMixin()
              this.addNotification({
                text: 'تم تجديد العقد بنجاح',
                color: 'success'
              })
            } catch {
              this.addNotification({
                text: 'حدث خطأ أثناء تجديد العقد',
                color: 'error'
              })
            } finally {
              this.refreshContracts()
            }
          }
        })
    },
    async getContractIntervals() {
      try {
        const Response = await contractsService.getContractIntervals()
        this.contractIntervals = Response.data.intervals
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل فترات العقد, برجاء المحاوله لاحقا',
          color: 'error'
        })
      }
    },
    changeGridView(status) {
      this.updateSetTableView(this.$route.name, status)
      this.gridView = status
    },
    setFilters() {
      let { realEstate, client } = this.$route?.query
      this.$nextTick(() => {
        if (
          realEstate &&
          Object.keys(realEstate.length) &&
          this.$refs?.contractFilter
        ) {
          realEstate = JSON.parse(realEstate)
          this.$refs.contractFilter.allProperties.push(realEstate)
          this.$refs.contractFilter.property = realEstate
          this.contractsFilters.realEstate = realEstate?._id
        } else if (
          client &&
          Object.keys(client.length) &&
          this.$refs?.contractFilter
        ) {
          client = JSON.parse(client)
          this.$refs.contractFilter.allClients.push(client)
          this.$refs.contractFilter.client = client
          this.contractsFilters.users = client?._id
        }
      })
    },
    async handlingContractLogic() {
      this.getContractsStatuses()
      await this.setFilters()
      this.loadData()
      if (!!this.$router.currentRoute.query.item) {
        const { visible, item, contractUtilities } = {
          ...this.$router.currentRoute.query
        }
        const query = {
          visible,
          contract: JSON.parse(item),
          contractUtilities: contractUtilities || {}
        }
        this.setDetails(query)
      }
    },
    async loadData() {
      try {
        this.isPageLoading = true
        const data = `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
        const res = await contractsService.getContracts(
          this.getContractFilters,
          data
        )
        this.pagination.pagesCount = Math.ceil(
          res?.data?.count / this.pagination.pageSize
        )
        this.pagination.count = res?.data?.count ?? 0
        this.contracts = res?.data?.contracts ?? []
        this.contracts = this.contracts?.map((contract) => ({
          ...contract,
          checked: false
        }))
        const {
          rentCount = 0,
          maintenanceCount = 0,
          propertyManagementCount = 0,
          count = 0
        } = res?.data ?? {}
        this.setFiltersValues({
          rentCount,
          maintenanceCount,
          propertyManagementCount,
          count
        })
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل العقود ، يرجى المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    refreshContracts() {
      this.closeContractModal()
      this.loadData()
    },
    closeContractModal() {
      this.showContractModal = false
      this.updatedContract = {}
    },
    async setDetails({ visible, contract, contractUtilities = {} }) {
      if (!this.$hasPermission('properties', 'contracts', 'list')) {
        return this.addNotification({
          text: 'ليس لديك الصلاحية لعرض العقد',
          color: 'error'
        })
      }
      this.setSideDetailes(visible)
      await this.getSingleContract(contract._id)
      this.contractDetails = { ...this.contractDetails, contractUtilities }

      return null
    },
    setPDFDetails({ visible, contract }) {
      if (!this.$hasPermission('properties', 'contracts', 'list')) {
        return this.addNotification({
          text: 'ليس لديك الصلاحية لعرض العقد',
          color: 'error'
        })
      }
      this.contractDetails = contract
      this.pdfStatus = visible
      return null
    },
    showSideVersions({ visible, id }) {
      if (!this.$hasPermission('properties', 'contracts', 'list')) {
        return this.addNotification({
          text: 'ليس لديك الصلاحية لعرض العقد',
          color: 'error'
        })
      }
      this.isSideVersionsVisible = visible
      this.currentSelectedContract = id
      return null
    },
    async getSingleContract(id) {
      try {
        const {
          data: { contract }
        } = await contractsService.getOneContractDetails(id)
        this.contractDetails = contract
      } catch (error) {
        this.addNotification({
          text: this.handleToasText(error),
          color: 'error'
        })
      }
    },
    async manageTerminateContract(
      { contractId, isIncluded, extraBody = {} },
      action = ''
    ) {
      const query = {
        id: contractId,
        ...extraBody,
        ...(action === 'terminateContract' || extraBody.evacDate
          ? { isIncluded: true }
          : { isIncluded: false }),
        environment: this.currentEnv._id
      }
      try {
        const {
          data: { contract }
        } = await contractsService.terminateContract(query)

        this.setSideDetailes(false)
        this.loadData()
        const title = this.getPopUpTitleText(isIncluded)
        this.showCancelContractModal = false
        const popUpBody = {
          title,
          message: isIncluded
            ? `وتم الغاء كل المعاملات والبيانات التي تلي تاريخ الإخلاء`
            : 'وتم ايقاف كل المعاملات والفواتير المرتبطة بالعقد'
        }
        this.$root.$confirm.open({
          ...popUpBody,
          options: {
            color: 'grey',
            width: 328,
            height: 278,
            zIndex: 200
          },
          confirmTxt: 'قائمه العقود',
          cancelButtonStatus: false
        })
        contract.needsApproval && this.showConfirmationPopupMixin()
      } catch (error) {
        this.addNotification({
          text: this.handleToasText(error),
          color: 'error'
        })
      }
    },
    async terminateContract({ contract, isIncluded }) {
      this.setSideDetailes(false)
      if (!this.$hasPermission('properties', 'contracts', 'delete')) {
        return this.addNotification({
          text: 'ليس لديك الصلاحية لحذف العقد',
          color: 'error'
        })
      }
      const popUpBody = this.getPopupBody(contract, isIncluded)
      return this.$root.$confirm
        .open({
          ...popUpBody,
          options: {
            color: 'red',
            width: 328,
            height: 278,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            if (
              contract.status === 'pendingRenewal' ||
              contract.status === 'approved' ||
              contract.typeName === 'propertyManagement'
            ) {
              this.manageTerminateContract(
                {
                  contractId: contract?._id,
                  isIncluded
                },
                contract.methodTitle
              )
            } else if (!isIncluded) {
              this.manageTerminateContract(
                {
                  contractId: contract?._id,
                  isIncluded
                },
                contract.methodTitle
              )
            } else {
              this.showCancelContractModal = true
              this.contractDetails = contract
            }
          }
        })
    },
    async handleFilters(filters = {}) {
      this.contractsFilters = { ...this.contractsFilters, ...filters }
      this.pagination.pageNumber = 1
      this.loadData()
    },
    setFiltersValues(filtersCount) {
      this.availableFilters.map((filter) => {
        filter.count = filtersCount[filter.code]
      })
    },
    togglePdfStatus() {
      this.pdfStatus = !this.pdfStatus
    },
    // When click on share internal and external button will trigger this function
    sharePdf(data) {
      const payload = {
        entityType: 'contract',
        entityId: data._id,
        media: this.restructureCompanyImages()
      }
      const config = {
        type: 'العقد',
        component: 'contractPdf',
        service: bondsService
      }
      this.generateShareCopy(payload, config)
    },
    exportPdfContract({ visible, contract }) {
      this.setSideDetailes(false)
      this.setPDFDetails({ visible, contract })
    },
    handleToasText(error) {
      if (error.response?.data?.error.includes('it has bonds')) {
        return 'لا يمكن حذف العقد لإرتباطه بسند '
      }
      return 'حدث خطاء اثناء حذف العقد'
    },
    displayNoteModal(val) {
      if (val) {
        this.setSideDetailes(!val)
        this.addNoteDialog = val
        this.noteModelTitle = {
          title: 'إضافة ملاحظة',
          subTitle: 'أدخل ملاحظتك هنا',
          btnText: 'حفظ'
        }
      } else {
        this.addNoteDialog = val
        this.setSideDetailes(!val)
        this.editNote = ''
      }
    },
    previewNote(note) {
      this.addNoteDialog = true
      this.editNote = note.body
      this.noteModelTitle = {
        title: 'عرض الملاحظة',
        subTitle: 'الملاحظة',
        btnText: 'اغلاق'
      }
    },
    async addContractNote(noteContent) {
      const body = {
        entityId: this.contractDetails._id,
        body: noteContent,
        postedBy: this.user._id
      }
      try {
        this.isAddNoteLoading = true
        const { data } = await contractsService.addContractNote(body)
        this.contractDetails.notes.push(data.note)
        this.displayNoteModal(false)
        this.addNotification({
          text: 'تم إضافة الملاحظة بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في إضافة الملاحظة، يرجى المحاولة مجددا',
          color: 'error'
        })
      } finally {
        this.isAddNoteLoading = false
      }
    },
    async deleteContractNote(payload, index) {
      try {
        await contractsService.deleteContractNote(
          this.contractDetails._id,
          payload._id
        )
        this.contractDetails.notes.splice(index, 1)

        this.addNotification({
          text: 'تم حذف الملاحظة بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في حذف الملاحظة، يرجى المحاولة مجددا',
          color: 'error'
        })
      }
    },
    setSideDetailes(val) {
      this.sideDetailes = val
    }
  },
  created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    this.handlingContractLogic()
    this.getContractIntervals()
    this.handleOpenSideDetailsUsingNewTab()
  },
  async mounted() {
    const requireCallAssets = Object.keys(this.companyImages).every(
      (imageKey) => {
        if (!this.companyImages[imageKey].location?.includes('base64')) {
          return false
        }
        return true
      }
    )
    !requireCallAssets && (await this.getCompanyImages(this.currentEnv._id))
  },
  metaInfo() {
    return {
      title: 'العقود'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_properties.scss';
@import '@/styles/material-dashboard/_team.scss';
@import '@/styles/material-dashboard/fastactions.scss';
@import '@/styles/material-dashboard/_statistics-cards.scss';
</style>
